import React, { useState } from 'react';
import { Calendar, Linkedin, Github, Send } from 'lucide-react';

const ContactSection = ({ isDarkMode }) => {
  const [formStatus, setFormStatus] = useState('idle'); // idle, submitting, success, error

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('submitting');

    const formData = new FormData(e.target);
    
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      });
      setFormStatus('success');
      e.target.reset();
    } catch (error) {
      console.error(error);
      setFormStatus('error');
    }
  };

  const contactMethods = [
    {
      icon: <Calendar className="w-6 h-6" />,
      title: "Schedule a Meeting",
      description: "Book a time that works best for you",
      action: "Schedule Now",
      link: "https://calendly.com/m-bassi-mail"
    },
    {
      icon: <Linkedin className="w-6 h-6" />,
      title: "Connect on LinkedIn",
      description: "Let's grow our professional network",
      action: "Connect",
      link: "https://linkedin.com/in/matteo-bassi"
    },
    {
      icon: <Github className="w-6 h-6" />,
      title: "Check My GitHub",
      description: "Explore my projects",
      action: "View Projects",
      link: "https://github.com/MBassi91"
    }
  ];

  return (
    <div className="space-y-8">
      <h2 className="text-3xl font-bold mb-8">Get in Touch</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        {contactMethods.map((method, index) => (
          <div 
            key={index} 
            className={`rounded-lg p-6 text-center transition-all duration-300
              ${isDarkMode 
                ? 'bg-white bg-opacity-5 hover:bg-opacity-10' 
                : 'bg-gray-100 hover:bg-gray-200 shadow-lg'}`}
          >
            <div className="flex flex-col items-center">
              <div className={`w-12 h-12 rounded-full flex items-center justify-center mb-4
                ${isDarkMode ? 'bg-blue-500 bg-opacity-20' : 'bg-blue-100'}`}>
                {method.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2">{method.title}</h3>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {method.description}
              </p>
              <a
                href={method.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 
                         rounded-full transition-colors text-sm font-semibold text-white"
              >
                {method.action}
              </a>
            </div>
          </div>
        ))}
      </div>

      <div className={`max-w-xl mx-auto rounded-lg p-6
        ${isDarkMode 
          ? 'bg-white bg-opacity-5' 
          : 'bg-gray-100 shadow-lg'}`}
      >
        <h3 className="text-xl font-semibold mb-6">Send Me a Message</h3>
        <form 
      name="contact"
      method="POST"
      data-netlify="true"
      onSubmit={handleSubmit}
      className="space-y-4"
    >
      <input type="hidden" name="form-name" value="contact" />
      
      <div>
        <label className="block text-sm font-medium mb-2">Name</label>
        <input
          type="text"
          name="name"
          className={`w-full px-4 py-2 rounded-lg transition-colors
            ${isDarkMode 
              ? 'bg-white bg-opacity-10 border border-gray-600 focus:border-blue-500' 
              : 'bg-white border border-gray-300 focus:border-blue-500'}`}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-2">Email</label>
        <input
          type="email"
          name="email"
          className={`w-full px-4 py-2 rounded-lg transition-colors
            ${isDarkMode 
              ? 'bg-white bg-opacity-10 border border-gray-600 focus:border-blue-500' 
              : 'bg-white border border-gray-300 focus:border-blue-500'}`}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-2">Message</label>
        <textarea
          name="message"
          rows="4"
          className={`w-full px-4 py-2 rounded-lg transition-colors
            ${isDarkMode 
              ? 'bg-white bg-opacity-10 border border-gray-600 focus:border-blue-500' 
              : 'bg-white border border-gray-300 focus:border-blue-500'}`}
          required
        ></textarea>
      </div>

      <button
        type="submit"
        disabled={formStatus === 'submitting'}
        className={`w-full py-3 bg-blue-600 hover:bg-blue-700 rounded-lg 
                   transition-colors flex items-center justify-center space-x-2
                   disabled:bg-gray-500 disabled:cursor-not-allowed`}
      >
        <Send className="w-5 h-5" />
        <span>
          {formStatus === 'submitting' ? 'Sending...' : 'Send Message'}
        </span>
      </button>

      {formStatus === 'success' && (
        <div className="text-green-500 text-center mt-4">
          Thank you for your message! I'll get back to you soon.
        </div>
      )}

      {formStatus === 'error' && (
        <div className="text-red-500 text-center mt-4">
          Something went wrong. Please try again.
        </div>
      )}
    </form>
      </div>
    </div>
  );
};

export default ContactSection;