//src/components/ChatBot.js
import React, { useState, useRef, useEffect } from 'react';
import { Send, User, Bot, RefreshCcw } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

const ChatBot = ({ fullScreen = false }) => {
  const initialMessages = [
    { 
      text: "Hi there! I'm Matteo's AI assistant. How can I help you today?", 
      isBot: true,
      timestamp: Date.now() 
    }
  ];
  
  const [messages, setMessages] = useState(initialMessages);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [botResponse, setBotResponse] = useState('');
  const [responseStarted, setResponseStarted] = useState(false);
  const [streamComplete, setStreamComplete] = useState(true);
  const [isFormal, setIsFormal] = useState(true);
  const [error, setError] = useState('');
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);

  const MAX_CHARS = 300;
  const modeLabel = isFormal ? "Formal 💼" : "Informal 😎";

  const getApiUrl = () => {
    return process.env.NODE_ENV === 'development'
      ? 'http://localhost:8888/.netlify/functions/chat'
      : '/.netlify/functions/chat';
  };

  const scrollToBottom = (behavior = 'smooth') => {
    if (messagesEndRef.current && chatContainerRef.current) {
      const container = chatContainerRef.current;
      const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;

      if (isNearBottom || behavior === 'instant') {
        container.scrollTo({
          top: container.scrollHeight,
          behavior
        });
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_CHARS) {
      setInput(value);
      setError('');
    } else {
      setError(`Message must be ${MAX_CHARS} characters or less`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '' || !streamComplete) return;
    if (input.length > MAX_CHARS) {
      setError(`Message must be ${MAX_CHARS} characters or less`);
      return;
    }

    const currentInput = input.trim();
    setInput('');
    setError('');
    setIsLoading(true);
    setBotResponse('');
    setResponseStarted(false);
    setStreamComplete(false);

    const newUserMessage = { 
      text: currentInput, 
      isBot: false,
      timestamp: Date.now()
    };
    
    const updatedMessages = [...messages, newUserMessage];
    setMessages(updatedMessages);
    scrollToBottom('instant');

    try {
      const apiUrl = getApiUrl();
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          message: currentInput, 
          mode: isFormal ? 'formal' : 'informal',
          history: updatedMessages.slice(-10)
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setMessages(prev => [...prev, { 
        text: '', 
        isBot: true,
        timestamp: Date.now()
      }]);
      scrollToBottom('instant');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          setStreamComplete(true);
          setIsLoading(false);
          break;
        }
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonData = line.slice(6);
            if (jsonData === '[DONE]') {
              setStreamComplete(true);
              setIsLoading(false);
              break;
            }
            
            try {
              const data = JSON.parse(jsonData);
              if (data.content) {
                setBotResponse(prev => prev + data.content);
                if (!responseStarted) {
                  setResponseStarted(true);
                  setIsLoading(false);
                }
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error calling chat function:', error);
      setMessages(prev => [...prev, { 
        text: "Sorry, I'm having trouble connecting right now. Please try again later.", 
        isBot: true,
        timestamp: Date.now()
      }]);
      setStreamComplete(true);
      setIsLoading(false);
    }

    inputRef.current?.focus();
  };

  useEffect(() => {
    if (botResponse) {
      setMessages(prev => {
        const newMessages = [...prev];
        newMessages[newMessages.length - 1].text = botResponse;
        return newMessages;
      });
      scrollToBottom();
    }
  }, [botResponse]);

  useEffect(() => {
    scrollToBottom('instant');
  }, []);

  const handleRefresh = () => {
    setMessages(initialMessages);
    setBotResponse('');
    setIsLoading(false);
    setResponseStarted(false);
    setStreamComplete(true);
    setInput('');
    setError('');
    inputRef.current?.focus();
  };


  return (
    <div className="flex flex-col h-full bg-gray-800 text-gray-200 rounded-lg border border-gray-700">
      {/* Header */}
      <div className="flex items-center justify-between p-3 border-b border-gray-700 bg-gray-900">
        <h3 className="font-semibold text-sm text-gray-100">Matteo's AI Assistant</h3>
        <div className="flex items-center space-x-3">
          <div className="flex items-center space-x-2">
            <span className="text-xs text-gray-300 font-medium">{modeLabel}</span>
            <button
              type="button"
              onClick={() => setIsFormal(!isFormal)}
              className={`relative w-12 h-6 rounded-full p-1 transition-all duration-300 ${isFormal ? "bg-gray-600" : "bg-gray-600"}`}
              title="Switch between formal and informal mode"
            >
              <div className={`w-4 h-4 rounded-full shadow transform transition-transform duration-300 
                ${isFormal ? 'translate-x-0 bg-blue-400' : 'translate-x-6 bg-pink-400'}`} 
              />
            </button>
          </div>
          <button
            type="button"
            onClick={handleRefresh}
            className="text-gray-300 hover:text-gray-100 transition-colors"
            title="Start a new chat"
          >
            <RefreshCcw size={16} />
          </button>
        </div>
      </div>

      {/* Messages Area */}
      <div 
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto p-3 space-y-4 bg-gray-800"
        style={{ height: 'calc(100% - 120px)' }}
      >
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.isBot ? 'justify-start' : 'justify-end'}`}>
            <div className={`flex items-start space-x-2 max-w-[80%] ${message.isBot ? 'flex-row' : 'flex-row-reverse'}`}>
              <div className={`w-7 h-7 rounded-full flex items-center justify-center flex-shrink-0 
                ${message.isBot ? 'bg-blue-500' : 'bg-blue-600'}`}>
                {message.isBot ? <Bot size={16} className="text-white" /> : <User size={16} className="text-white" />}
              </div>
              <div className={`px-3 py-2 rounded-lg shadow-sm 
                ${message.isBot ? 'bg-gray-700 border border-gray-600' : 'bg-blue-700 border border-blue-600'}`}>
                <ReactMarkdown className="prose prose-sm break-words text-gray-100">
                  {message.text}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        ))}

        {isLoading && !responseStarted && (
          <div className="flex justify-start">
            <div className="flex items-start space-x-2">
              <div className="w-7 h-7 rounded-full flex items-center justify-center flex-shrink-0 bg-blue-500">
                <Bot size={16} className="text-white" />
              </div>
              <div className="bg-gray-700 p-2 rounded-lg border border-gray-600">
                <div className="typing-indicator flex space-x-1">
                  <span className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"></span>
                  <span className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></span>
                  <span className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div ref={messagesEndRef} className="h-0" />
      </div>

      {/* Input Area */}
      <div className="h-[60px] border-t border-gray-700 bg-gray-900">
        <form onSubmit={handleSubmit} className="h-full p-2">
          <div className="flex flex-col space-y-1">
            <div className="flex space-x-2">
              <div className="flex-1 relative">
                <input
                  ref={inputRef}
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  placeholder="Ask something about Matteo..."
                  className="w-full p-2 border border-gray-700 rounded-md bg-gray-800 text-gray-200 
                           placeholder-gray-400 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={!streamComplete}
                />
                <div className="absolute right-2 top-2 text-xs text-gray-400">
                  {input.length}/{MAX_CHARS}
                </div>
              </div>
              <button 
                type="submit" 
                className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-1.5 rounded-md 
                         transition-colors disabled:bg-blue-400 disabled:cursor-not-allowed text-sm"
                disabled={!streamComplete || input.trim() === '' || input.length > MAX_CHARS}
              >
                <Send size={16} />
              </button>
            </div>
            {error && (
              <div className="text-xs text-red-400 px-2">
                {error}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatBot;