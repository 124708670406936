import React from 'react';

const Achievement = ({ icon, title, metric, description, details, isDarkMode }) => {
  return (
    <div className={`
      relative p-8 rounded-xl transition-all duration-300 transform hover:scale-105
      ${isDarkMode 
        ? 'bg-white bg-opacity-5 hover:bg-opacity-10' 
        : 'bg-gray-100 hover:bg-gray-200 shadow-lg'}
    `}>
      {/* Icon wrapper with gradient background */}
      <div className={`
        absolute -top-4 left-1/2 transform -translate-x-1/2
        w-16 h-16 rounded-xl flex items-center justify-center
        bg-gradient-to-br ${isDarkMode 
          ? 'from-blue-500/20 to-purple-500/20' 
          : 'from-blue-100 to-purple-100'}
      `}>
        <div className={`
          ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}
        `}>
          {icon}
        </div>
      </div>

      {/* Content with enhanced spacing */}
      <div className="mt-8 text-center">
        {/* Metric with gradient text */}
        <h3 className={`
          text-3xl font-bold mb-2 bg-clip-text text-transparent
          bg-gradient-to-r ${isDarkMode 
            ? 'from-blue-400 to-purple-400' 
            : 'from-blue-600 to-purple-600'}
        `}>
          {metric}
        </h3>

        {/* Title with proper contrast */}
        <p className={`
          text-lg font-semibold mb-3
          ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}
        `}>
          {title}
        </p>

        {/* Description with improved readability */}
        <p className={`
          mb-2 leading-relaxed
          ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
        `}>
          {description}
        </p>

        {/* Details with subtle emphasis */}
        {details && (
          <p className={`
            text-sm italic
            ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
          `}>
            {details}
          </p>
        )}
      </div>

      {/* Subtle decorative element */}
      <div className={`
        absolute bottom-0 left-0 right-0 h-1 rounded-b-xl
        bg-gradient-to-r ${isDarkMode 
          ? 'from-blue-500/20 via-purple-500/20 to-blue-500/20' 
          : 'from-blue-200 via-purple-200 to-blue-200'}
      `} />
    </div>
  );
};

export default Achievement;