import React from 'react';
import { ChefHat, Gamepad, Dumbbell, Sparkle, Code, BookOpen, Heart } from 'lucide-react';

const PersonalitySection = ({ isDarkMode, onContactClick  }) => {
  const passions = [
    {
      icon: <ChefHat className="w-8 h-8" />,
      title: "Kitchen Explorer",
      description: "Love experimenting with different cuisines and creating new recipes. The kitchen is my lab for creativity and spontaneous innovation."
    },
    {
      icon: <Code className="w-8 h-8" />,
      title: "Weekend Builder",
      description: "Currently crafting an AI Game Master and experimenting with Stable Diffusion. There's something magical about bringing creative ideas to life through code."
    },
    {
      icon: <Gamepad className="w-8 h-8" />,
      title: "Casual Gamer",
      description: "Enjoy unwinding with RPGs and RTS games. Whether it's building civilizations or exploring vast worlds, gaming is my go-to relaxation."
    }
  ];

  const quickFacts = [
    {
      icon: <Dumbbell className="w-5 h-5 text-purple-400" />,
      fact: "Recently picked up squash - always up for a friendly match!"
    },
    {
      icon: <ChefHat className="w-5 h-5 text-orange-400" />,
      fact: "Believer in espresso done right - a cup of coffee is my daily ritual"
    },
    {
      icon: <BookOpen className="w-5 h-5 text-blue-400" />,
      fact: "Perpetual learner - currently diving into game design principles"
    },
    {
      icon: <Sparkle className="w-5 h-5 text-yellow-400" />,
      fact: "Weekend project enthusiast - from cooking experiments to AI tinkering"
    }
  ];

  return (
    <div className="space-y-8">
      <h2 className="text-3xl font-bold mb-8">Beyond Tech</h2>
      
      <div className="prose prose-lg max-w-none mb-8">
        <p className={`text-xl leading-relaxed ${
          isDarkMode ? 'text-gray-300' : 'text-gray-700'
        }`}>
          While AI and technology are my professional focus, life is all about exploring different 
          passions and constantly trying new things. Here's what keeps me curious and energized 
          outside of work.
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {passions.map((passion, index) => (
          <div 
            key={index} 
            className={`rounded-xl p-6 transition-all duration-300 transform hover:scale-105
              ${isDarkMode 
                ? 'bg-white bg-opacity-5 hover:bg-opacity-10' 
                : 'bg-gray-100 hover:bg-gray-200 shadow-lg'}`}
          >
            <div className="flex flex-col items-center text-center">
              <div className={`mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`}>
                {passion.icon}
              </div>
              <h3 className={`text-xl font-semibold mb-2 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-800'
              }`}>
                {passion.title}
              </h3>
              <p className={
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }>
                {passion.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      
      <div className={`mt-12 rounded-lg p-6 ${
        isDarkMode ? 'bg-white bg-opacity-5' : 'bg-gray-100'
      }`}>
        <h3 className="text-2xl font-semibold mb-6">Quick Facts</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {quickFacts.map((item, index) => (
            <div 
              key={index} 
              className={`flex items-center p-3 rounded-lg transition-colors duration-300 ${
                isDarkMode 
                  ? 'hover:bg-white hover:bg-opacity-5' 
                  : 'hover:bg-gray-200'
              }`}
            >
              <div className="mr-3">{item.icon}</div>
              <span className={
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }>{item.fact}</span>
            </div>
          ))}
        </div>
      </div>

      <div className={`mt-12 rounded-lg p-8 text-center ${
        isDarkMode 
          ? 'bg-gradient-to-r from-blue-500/20 to-purple-600/20' 
          : 'bg-gradient-to-r from-blue-100 to-purple-100'
      }`}>
        <h3 className={`text-2xl font-bold mb-4 ${
          isDarkMode ? 'text-white' : 'text-gray-800'
        }`}>
          Let's Connect!
        </h3>
        <p className={`mb-6 ${
          isDarkMode ? 'text-gray-300' : 'text-gray-600'
        }`}>
          Always excited to meet fellow tech enthusiasts, gamers, and cooking experimenters. 
          Let's chat about projects, games, or exchange recipes!
        </p>
        <button 
          onClick={onContactClick}
          className={`inline-flex items-center px-6 py-3 rounded-full font-semibold
            transition-colors ${
              isDarkMode 
                ? 'bg-blue-500 hover:bg-blue-600 text-white' 
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`}
        >
          <Heart className="w-5 h-5 mr-2" />
          Get In Touch
        </button>
      </div>
    </div>
  );
};

export default PersonalitySection;