// src/components/HeroSection.js
import React from 'react';
import { Calendar, ArrowRight } from 'lucide-react';

const HeroSection = ({ isDarkMode }) => {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center relative z-10">
          <h1 className={`text-6xl font-bold mb-6 ${
            isDarkMode 
              ? 'bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500'
              : 'text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-700'
          }`}>
            Matteo Bassi
          </h1>
          <p className={`text-3xl font-semibold mb-4 ${
            isDarkMode ? 'text-white' : 'text-gray-800'
          }`}>
            AI & Product Lead
          </p>
          <p className={`text-xl max-w-2xl mx-auto mb-8 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
          Leading digital transformation through innovative solutions and strategic thinking.
          From AI systems to product strategy, turning complex challenges into business success.
          </p>

          <div className="flex justify-center gap-4">
            <a 
              href="https://calendly.com/m-bassi-mail" 
              target="_blank"
              rel="noopener noreferrer"
              className="group px-8 py-3 bg-blue-600 hover:bg-blue-700 rounded-full font-semibold 
                transition-all transform hover:scale-105 flex items-center"
            >
              <Calendar className="mr-2 h-5 w-5" />
              Schedule a Call
              <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;