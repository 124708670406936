// src/components/MagneticButton.js
import React, { useState, useRef } from 'react';

const MagneticButton = ({ 
  children, 
  className = '', 
  strength = 30,
  href, // Add href prop
  ...props 
}) => {
  const buttonRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    if (!buttonRef.current) return;

    const rect = buttonRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const distanceX = e.clientX - centerX;
    const distanceY = e.clientY - centerY;

    setPosition({
      x: distanceX / strength,
      y: distanceY / strength,
    });
  };

  const handleMouseLeave = () => {
    setPosition({ x: 0, y: 0 });
  };

  const sharedProps = {
    ref: buttonRef,
    onMouseMove: handleMouseMove,
    onMouseLeave: handleMouseLeave,
    className: `transform transition-transform duration-100 hover:scale-105 ${className}`,
    style: {
      transform: `translate(${position.x}px, ${position.y}px)`,
    },
    ...props
  };

  // Return an anchor tag if href is provided, otherwise a button
  return href ? (
    <a href={href} target="_blank" rel="noopener noreferrer" {...sharedProps}>
      {children}
    </a>
  ) : (
    <button {...sharedProps}>
      {children}
    </button>
  );
};

export default MagneticButton;