import React from 'react';
import { Briefcase, Calendar, MapPin, ArrowUpRight } from 'lucide-react';

const ExperienceTimeline = ({ isDarkMode }) => {
  const experiences = [
    {
      title: "AI Tech Lead",
      company: "Leading Luxury Retailer",
      location: "Zürich, Switzerland",
      period: "2023 - Present",
      summary: "Leading enterprise-wide AI initiatives and managing cross-functional teams in developing cutting-edge AI solutions.",
      keyAchievements: [
        "Architected and launched company-wide AI platform",
        "Led development of multiple LLM-powered applications",
        "Established AI governance and best practices",
        "Managed team of AI/ML engineers and data scientists"
      ],
      impactAreas: [
        {
          title: "Technical Leadership",
          points: ["RAG Systems", "LLM Applications", "MLOps Pipeline"]
        },
        {
          title: "Business Impact",
          points: ["Cost Optimization", "Process Automation", "Innovation Strategy"]
        }
      ]
    },
    {
      title: "Data Scientist & Product Lead",
      company: "Leading Luxury Retailer",
      location: "Zürich, Switzerland",
      period: "2020 - 2023",
      summary: "Led analytics solutions development and product strategy for data-driven initiatives.",
      keyAchievements: [
        "Developed Customer Data Platform strategy",
        "Led implementation of multiple ML models",
        "Established data science best practices",
        "Managed analytics product roadmap"
      ],
      impactAreas: [
        {
          title: "Product Development",
          points: ["Analytics Platform", "ML Models", "Data Pipeline"]
        },
        {
          title: "Team Leadership",
          points: ["Agile Practices", "Technical Mentorship", "Stakeholder Management"]
        }
      ]
    },
    {
      title: "Data Analyst",
      company: "Global Travel Tech Company",
      location: "Zürich, Switzerland",
      period: "2018 - 2020",
      summary: "Developed predictive analytics solutions for hotel demand forecasting and pricing optimization.",
      keyAchievements: [
        "Built predictive analytics models",
        "Implemented dynamic pricing system",
        "Created automated reporting solutions",
        "Improved forecast accuracy"
      ],
      impactAreas: [
        {
          title: "Analytics",
          points: ["Predictive Modeling", "Price Optimization", "Data Visualization"]
        },
        {
          title: "Process Improvement",
          points: ["Automation", "Reporting", "Data Quality"]
        }
      ]
    },
    {
      title: "Technology & Strategy Analyst",
      company: "Leading Consulting Firm",
      location: "Milan, Italy",
      period: "2016 - 2017",
      summary: "Focused on FinTech innovation and digital transformation projects, bridging technical solutions with business strategy.",
      keyAchievements: [
        "Developed NLP-powered chatbot solutions",
        "Created technical strategies for FinTech implementation",
        "Led client workshops and technical presentations",
        "Contributed to digital transformation initiatives"
      ],
      impactAreas: [
        {
          title: "Technical Innovation",
          points: ["FinTech Solutions", "Chatbot Development", "Process Automation"]
        },
        {
          title: "Strategic Planning",
          points: ["Digital Strategy", "Client Advisory", "Innovation Planning"]
        }
      ]
    }
  ];

  return (
    <div className="space-y-12">
      <h2 className="text-3xl font-bold mb-8">Professional Journey</h2>
      
      <div className="relative space-y-12">
        {experiences.map((experience, index) => (
          <div key={index} className="relative">
            {/* Timeline line - hidden on small screens */}
            {index !== experiences.length - 1 && (
              <div className={`absolute left-8 top-16 bottom-0 w-0.5 
                hidden md:block
                ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`} 
              />
            )}
            
            <div className="relative flex flex-col md:flex-row md:gap-6">
              {/* Timeline dot - centered on mobile */}
              <div className={`flex-shrink-0 w-16 h-16 rounded-full 
                flex items-center justify-center mb-4 md:mb-0
                mx-auto md:mx-0
                ${isDarkMode ? 'bg-blue-500 bg-opacity-20' : 'bg-blue-100'}`}>
                <Briefcase className={`w-8 h-8 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              </div>
              
              {/* Content */}
              <div className="flex-grow">
                <div className={`rounded-lg p-4 md:p-6 transition-all duration-300
                  ${isDarkMode 
                    ? 'bg-white bg-opacity-5 hover:bg-opacity-10' 
                    : 'bg-gray-100 hover:bg-gray-200 shadow-lg'}`}>
                  
                  {/* Header - Stacked on mobile */}
                  <div className="space-y-2 md:space-y-0 md:flex md:justify-between md:items-start md:gap-4 mb-4">
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`}>{experience.title}</h3>
                      <p className={`text-lg ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-700'
                      }`}>{experience.company}</p>
                    </div>
                    {/* Meta info - Horizontal on mobile */}
                    <div className="flex flex-wrap gap-4 text-sm">
                      <div className="flex items-center">
                        <Calendar className="w-4 h-4 mr-2 flex-shrink-0" />
                        <span>{experience.period}</span>
                      </div>
                      <div className="flex items-center">
                        <MapPin className="w-4 h-4 mr-2 flex-shrink-0" />
                        <span>{experience.location}</span>
                      </div>
                    </div>
                  </div>
                  
                  {/* Summary */}
                  <p className={`mb-4 text-sm md:text-base ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>{experience.summary}</p>
                  
                  {/* Key Achievements - Compact on mobile */}
                  <div className="mb-4">
                    <h4 className="font-semibold mb-2 text-sm md:text-base">Key Achievements</h4>
                    <ul className="space-y-2">
                      {experience.keyAchievements.map((achievement, i) => (
                        <li key={i} className="flex items-start">
                          <ArrowUpRight className="w-4 h-4 mr-2 mt-1 flex-shrink-0 text-blue-500" />
                          <span className={`text-sm md:text-base
                            ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                            {achievement}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  {/* Impact Areas - Single column on mobile */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {experience.impactAreas.map((area, i) => (
                      <div key={i} className={`p-3 md:p-4 rounded-lg
                        ${isDarkMode 
                          ? 'bg-gray-800 bg-opacity-50' 
                          : 'bg-white shadow-sm'}`}>
                        <h5 className="font-semibold mb-2 text-sm md:text-base">{area.title}</h5>
                        <ul className="space-y-1">
                          {area.points.map((point, j) => (
                            <li key={j} className="flex items-center">
                              <span className={`w-1.5 h-1.5 rounded-full mr-2 flex-shrink-0
                                ${isDarkMode ? 'bg-blue-400' : 'bg-blue-500'}`} 
                              />
                              <span className={`text-sm
                                ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                {point}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperienceTimeline;