import React from 'react';
import { Terminal, Briefcase, Lightbulb, BarChart3 } from 'lucide-react';

const SkillsMatrix = ({ isDarkMode }) => {
  const skillCategories = [
    {
      icon: <Terminal className="w-6 h-6 text-blue-500" />,
      title: "Technical Expertise",
      skills: [
        { name: "AI/ML Solutions", level: 95 },
        { name: "LLMs & RAG Systems", level: 90 },
        { name: "Cloud Architecture", level: 85 },
        { name: "Data Science", level: 90 }
      ]
    },
    {
      icon: <Briefcase className="w-6 h-6 text-purple-500" />,
      title: "Leadership & Strategy",
      skills: [
        { name: "Team Leadership", level: 90 },
        { name: "Strategic Planning", level: 85 },
        { name: "Stakeholder Management", level: 90 },
        { name: "Innovation Management", level: 85 }
      ]
    },
    {
      icon: <Lightbulb className="w-6 h-6 text-yellow-500" />,
      title: "Product Development",
      skills: [
        { name: "Product Strategy", level: 85 },
        { name: "User Experience", level: 80 },
        { name: "Agile Methods", level: 90 },
        { name: "MVP Development", level: 85 }
      ]
    },
    {
      icon: <BarChart3 className="w-6 h-6 text-green-500" />,
      title: "Business Operations",
      skills: [
        { name: "Process Optimization", level: 85 },
        { name: "Resource Management", level: 80 },
        { name: "Analytics & KPIs", level: 90 },
        { name: "Risk Management", level: 85 }
      ]
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {skillCategories.map((category, index) => (
          <div
            key={index}
            className={`
              rounded-xl p-6 transition-all duration-300 transform hover:scale-105
              ${isDarkMode 
                ? 'bg-white bg-opacity-5 hover:bg-opacity-10 text-white' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-900 shadow-lg'}
            `}
          >
            <div className="flex items-center mb-6">
              {category.icon}
              <h3 className={`ml-3 text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {category.title}
              </h3>
            </div>
            <div className="space-y-4">
              {category.skills.map((skill, skillIndex) => (
                <div key={skillIndex}>
                  <div className="flex justify-between mb-1">
                    <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                      {skill.name}
                    </span>
                  </div>
                  <div className={`w-full h-2 rounded-full overflow-hidden ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`}>
                    <div
                      className="h-full rounded-full bg-gradient-to-r from-blue-500 to-purple-500
                               transition-all duration-500 ease-out"
                      style={{ 
                        width: `${skill.level}%`,
                        animation: `growWidth 1s ease-out ${skillIndex * 0.2}s` 
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsMatrix;