import React, { useState } from 'react';
import { 
  Brain, Languages, Image, Scale, 
  Network, Target, ChevronDown 
} from 'lucide-react';
import { AnimatedSection } from './AnimatedSection';

const ProjectCard = ({ project, isDarkMode }) => (
  <div 
    className={`
      rounded-xl p-6 transition-all duration-300 hover:transform hover:scale-[1.02]
      ${isDarkMode 
        ? 'bg-white bg-opacity-5 hover:bg-opacity-10' 
        : 'bg-gray-100 hover:bg-gray-200 shadow-lg'}
    `}
  >
    <div className="flex items-center gap-4 mb-4">
      <div className={`p-2 rounded-full 
        ${isDarkMode ? 'bg-blue-500 bg-opacity-20' : 'bg-blue-100'}`}>
        {project.icon}
      </div>
      <div>
        <h3 className={`text-xl font-bold ${
          isDarkMode ? 'text-blue-400' : 'text-blue-600'
        }`}>
          {project.title}
        </h3>
        <p className={`text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          {project.role}
        </p>
      </div>
    </div>
    
    <p className={`mb-4 ${
      isDarkMode ? 'text-gray-300' : 'text-gray-700'
    }`}>
      {project.description}
    </p>
    
    <div className="mb-4">
      <h4 className={`text-sm font-semibold mb-2 ${
        isDarkMode ? 'text-gray-300' : 'text-gray-700'
      }`}>
        Key Impacts:
      </h4>
      <ul className="space-y-1">
        {project.impact.map((impact, i) => (
          <li key={i} className="flex items-center text-sm">
            <span className={`w-1.5 h-1.5 rounded-full mr-2 ${
              isDarkMode ? 'bg-blue-400' : 'bg-blue-500'
            }`} />
            <span className={
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }>
              {impact}
            </span>
          </li>
        ))}
      </ul>
    </div>

    <div className="mt-4 space-y-3">
      <div>
        <span className={`text-sm font-semibold ${
          isDarkMode ? 'text-gray-300' : 'text-gray-700'
        }`}>
          Methodology:
        </span>
        <span className={`text-sm ml-2 ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          {project.methodology}
        </span>
      </div>
      
      <div className="flex flex-wrap gap-2">
        {project.technologies.map((tech, i) => (
          <span
            key={i}
            className={`px-3 py-1 rounded-full text-sm ${
              isDarkMode 
                ? 'bg-blue-500 bg-opacity-20 text-blue-300' 
                : 'bg-blue-100 text-blue-700'
            }`}
          >
            {tech}
          </span>
        ))}
      </div>
    </div>
  </div>
);

const ProjectShowcase = ({ isDarkMode }) => {
  const [showAll, setShowAll] = useState(false);

  const featuredProjects = [
    {
      icon: <Brain className="w-6 h-6" />,
      title: "Enterprise AI Platform",
      role: "Technical Lead & Product Owner",
      description: "Led the development of a comprehensive AI platform enabling organization-wide AI adoption and governance. Architected both backend infrastructure and frontend interfaces.",
      impact: [
        "Centralized AI resource management",
        "Reduced model deployment time by 70%",
        "Implemented robust monitoring and governance"
      ],
      methodology: "Modular architecture with continuous deployment",
      technologies: ["Cloud Infrastructure", "MLOps", "React", "Python", "Docker"]
    },
    {
      icon: <Languages className="w-6 h-6" />,
      title: "Advanced Translation System",
      role: "AI Solution Architect",
      description: "Developed an intelligent translation system handling multiple languages for e-commerce and internal communications, integrating LLMs with domain-specific knowledge.",
      impact: [
        "Supported 10+ language pairs",
        "90% reduction in translation costs",
        "Real-time translation capabilities"
      ],
      methodology: "Iterative development with continuous learning",
      technologies: ["NLP", "LLMs", "Neural Translation", "API Design"]
    },
    {
      icon: <Image className="w-6 h-6" />,
      title: "AI Image Generation Pipeline",
      role: "Technical Lead",
      description: "Created a custom image generation system for product visualization, integrating multiple AI models with specific style transfer capabilities.",
      impact: [
        "300+ daily image generations",
        "Reduced image production time by 80%",
        "Implemented style consistency controls"
      ],
      methodology: "Rapid prototyping with feedback loops",
      technologies: ["Computer Vision", "GANs", "LoRA", "Custom Training"]
    },
    {
      icon: <Scale className="w-6 h-6" />,
      title: "Contract Compliance System",
      role: "Solution Architect & Product Owner",
      description: "Architected an AI-powered system for automated contract analysis and compliance checking, integrating RAG with custom validation rules.",
      impact: [
        "95% reduction in manual review time",
        "Enhanced compliance accuracy",
        "Automated risk assessment"
      ],
      methodology: "Risk-based development approach",
      technologies: ["LLMs", "RAG", "Natural Language Processing", "Rule Engines"]
    }
  ];

  const additionalProjects = [
    {
      icon: <Target className="w-6 h-6" />,
      title: "Customer Segmentation Engine",
      role: "Data Science Lead",
      description: "Developed an advanced segmentation system integrating multiple data sources for precise customer targeting and personalization.",
      impact: [
        "Created 20+ dynamic segments",
        "Improved campaign performance by 40%",
        "Real-time segment updates"
      ],
      methodology: "Iterative clustering with business validation",
      technologies: ["Machine Learning", "Data Pipeline", "Real-time Analytics"]
    },
    {
      icon: <Network className="w-6 h-6" />,
      title: "Intelligent Search Platform",
      role: "Technical Architect",
      description: "Built a semantic search system with multi-modal capabilities, handling text, images, and structured data.",
      impact: [
        "85% improvement in search relevancy",
        "Cross-modal search capabilities",
        "Scalable to millions of items"
      ],
      methodology: "Microservices architecture with A/B testing",
      technologies: ["Vector Search", "Semantic Models", "Distributed Systems"]
    }
  ];

  const allProjects = [...featuredProjects, ...additionalProjects];
  const displayedProjects = showAll ? allProjects : featuredProjects;

  return (
    <div className="space-y-8">
      <h2 className={`text-3xl font-bold mb-8 ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>
        Featured Projects
      </h2>
      
      <div className="grid grid-cols-1 gap-8">
        {displayedProjects.map((project, index) => (
          <AnimatedSection 
            key={project.title} 
            animation="fade-up"
            className="transition-all duration-500"
            style={{ animationDelay: `${index * 100}ms` }}
          >
            <ProjectCard project={project} isDarkMode={isDarkMode} />
          </AnimatedSection>
        ))}
      </div>

      {!showAll && additionalProjects.length > 0 && (
        <div className="text-center mt-8">
          <button
            onClick={() => setShowAll(true)}
            className={`
              group px-6 py-3 rounded-full transition-all duration-300 
              flex items-center gap-2 mx-auto
              ${isDarkMode 
                ? 'bg-blue-500 hover:bg-blue-600' 
                : 'bg-blue-600 hover:bg-blue-700'} 
              text-white font-semibold
            `}
          >
            View More Projects
            <ChevronDown className="w-5 h-5 group-hover:transform group-hover:translate-y-1 transition-transform" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectShowcase;