// src/App.js
import React, { useState, useEffect } from 'react';
import { 
  User, Briefcase, Code, Mail, Linkedin, 
  Github, MessageSquare, Moon, Sun,  
  Brain, Globe, Heart, Target
} from 'lucide-react';
import { useTheme } from './hooks/useTheme';
import { AnimatedSection } from './components/AnimatedSection';
import MagneticButton from './components/MagneticButton';
import ParticleBackground from './components/ParticleBackground';
import ChatBot from './components/ChatBot';
import HeroSection from './components/HeroSection';
import Achievement from './components/Achievement';
import ExperienceTimeline from './components/ExperienceTimeline';
import ProjectShowcase from './components/ProjectShowcase';
import PersonalitySection from './components/PersonalitySection';
import ContactSection from './components/ContactSection';
import SkillsMatrix from './components/SkillsMatrix';

const App = () => {
  const [activeTab, setActiveTab] = useState('about');
  const [isDarkMode, setIsDarkMode] = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  // Handle initial loading animation
  useEffect(() => {
    setIsLoading(false);
  }, []);
  
  // Enhanced achievements with animations
  const achievements = [
    {
      icon: <Brain className="w-8 h-8" />,
      title: "AI Solutions",
      metric: "15+",
      description: "Enterprise AI systems deployed",
      details: "From RAG to Computer Vision"
    },
    {
      icon: <Globe className="w-8 h-8" />,
      title: "Global Reach",
      metric: "5+",
      description: "Countries with active implementations",
      details: "Driving international innovation"
    },
    {
      icon: <User className="w-8 h-8" />,
      title: "Team Leadership",
      metric: "12+",
      description: "Data scientists mentored & led",
      details: "Building world-class AI teams"
    },
    {
      icon: <Target className="w-8 h-8" />,
      title: "Project Success",
      metric: "20+",
      description: "Major projects delivered",
      details: "End-to-end ownership"
    }
  ];

  const navigationItems = [
    { id: 'about', icon: <User />, label: 'About Me' },
    { id: 'experience', icon: <Briefcase />, label: 'Experience' },
    { id: 'projects', icon: <Code />, label: 'Projects' },
    { id: 'personality', icon: <Heart />, label: 'Beyond Tech' },
    { id: 'contact', icon: <Mail />, label: 'Contact' },
    { id: 'chat', icon: <MessageSquare />, label: 'AI Chat' }
  ];

  // Enhanced theme classes with glass morphism
  const themeClasses = isDarkMode
    ? 'bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white'
    : 'bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 text-white';

  return (
    <div className={`min-h-screen transition-all duration-500 ${themeClasses}
      ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
      <ParticleBackground isDarkMode={isDarkMode} />

      {/* Enhanced Theme Toggle with magnetic effect */}
      <MagneticButton 
        onClick={() => setIsDarkMode(!isDarkMode)}
        className={`fixed top-4 right-4 p-3 rounded-full backdrop-blur-sm
          transition-all duration-300 z-50 shadow-lg
          ${isDarkMode 
            ? 'bg-gray-800/80 hover:bg-gray-700/80 text-yellow-400' 
            : 'bg-white/20 hover:bg-white/30 text-white'}`}
      >
        {isDarkMode ? <Sun className="w-6 h-6" /> : <Moon className="w-6 h-6" />}
      </MagneticButton>

      {/* Animated Hero Section */}
      <HeroSection isDarkMode={isDarkMode} />
      <AnimatedSection animation="fade-up">
        <SkillsMatrix isDarkMode={isDarkMode} />
      </AnimatedSection>

        {/* Achievement Grid with enhanced theme support */}
        <AnimatedSection animation="fade-up" className="max-w-7xl mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {achievements.map((achievement, index) => (
              <div 
                key={index} 
                className="transform transition-all duration-500"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <Achievement 
                  {...achievement} 
                  isDarkMode={isDarkMode}
                />
              </div>
            ))}
          </div>
        </AnimatedSection>

      {/* Main Content Area with enhanced animations */}
      <main className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Enhanced Navigation Sidebar */}
          <nav className="lg:col-span-1">
            <div className="sticky top-8 space-y-2">
              {navigationItems.map((item) => (
                <MagneticButton
                  key={item.id}
                  onClick={() => setActiveTab(item.id)}
                  className={`w-full px-6 py-3 rounded-lg text-left transition-all duration-300 
                    flex items-center backdrop-blur-sm
                    ${activeTab === item.id 
                      ? `${isDarkMode ? 'bg-blue-600/80' : 'bg-white/20'} 
                         shadow-lg transform translate-x-2` 
                      : 'hover:bg-white hover:bg-opacity-5'}`}
                >
                  <span className="mr-3">{item.icon}</span>
                  <span className="text-lg font-medium">{item.label}</span>
                </MagneticButton>
              ))}
            </div>
          </nav>

          {/* Enhanced Content Area with transitions */}
          <div className="lg:col-span-3 min-h-[600px]">
            <AnimatedSection 
              animation="fade-up"
              className={`rounded-2xl backdrop-blur-sm p-6 
                ${isDarkMode ? 'bg-gray-800/50' : 'bg-white/10'}
                shadow-xl transition-all duration-500`}
            >
              {activeTab === 'about' && (
                <div className="prose prose-lg prose-invert max-w-none">
                  <h2 className="text-3xl font-bold mb-6">About Me</h2>
                  <p className="text-xl leading-relaxed">
                    As an AI & Data Science Lead, I blend technical expertise with strategic thinking 
                    to deliver innovative solutions that drive business transformation. My journey 
                    spans from developing RAG systems to leading cross-functional teams across global projects.
                  </p>
                  <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className={`rounded-xl p-6 transition-all duration-300
                      ${isDarkMode ? 'bg-gray-700/50' : 'bg-white/10'} 
                      hover:transform hover:scale-105`}>
                      <h3 className="text-xl font-semibold mb-4">Technical Focus</h3>
                      <ul className="space-y-2">
                        <li className="flex items-center">
                          <span className="mr-2">🤖</span>
                          Generative AI & LLMs
                        </li>
                        <li className="flex items-center">
                          <span className="mr-2">🔍</span>
                          RAG Systems
                        </li>
                        <li className="flex items-center">
                          <span className="mr-2">🎯</span>
                          Machine Learning
                        </li>
                        <li className="flex items-center">
                          <span className="mr-2">📊</span>
                          Data Analytics
                        </li>
                      </ul>
                    </div>
                    <div className={`rounded-xl p-6 transition-all duration-300
                      ${isDarkMode ? 'bg-gray-700/50' : 'bg-white/10'}
                      hover:transform hover:scale-105`}>
                      <h3 className="text-xl font-semibold mb-4">Leadership</h3>
                      <ul className="space-y-2">
                        <li className="flex items-center">
                          <span className="mr-2">👥</span>
                          Team Building
                        </li>
                        <li className="flex items-center">
                          <span className="mr-2">🎯</span>
                          Project Management
                        </li>
                        <li className="flex items-center">
                          <span className="mr-2">💡</span>
                          Innovation Strategy
                        </li>
                        <li className="flex items-center">
                          <span className="mr-2">🤝</span>
                          Stakeholder Management
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'experience' && 
                <AnimatedSection animation="fade-up">
                  <ExperienceTimeline isDarkMode={isDarkMode} />
                </AnimatedSection>
              }
              {activeTab === 'projects' && 
                <AnimatedSection animation="fade-up">
                  <ProjectShowcase isDarkMode={isDarkMode} />
                </AnimatedSection>
              }
              {activeTab === 'personality' && 
                <AnimatedSection animation="fade-up">
                  <PersonalitySection 
                    isDarkMode={isDarkMode} 
                    onContactClick={() => setActiveTab('contact')} 
                  />
                </AnimatedSection>
              }
              {activeTab === 'contact' && 
                <AnimatedSection animation="fade-up">
                  <ContactSection isDarkMode={isDarkMode} />
                </AnimatedSection>
              }
              {activeTab === 'chat' && (
                <div className="h-[600px] rounded-xl overflow-hidden bg-gray-800" 
                    style={{ contain: 'paint' }}> {/* Add this style */}
                  <div className="h-full">
                    <ChatBot fullScreen={true} />
                  </div>
                </div>
              )}
            </AnimatedSection>
          </div>
        </div>
      </main>


      {/* Enhanced Footer */}
      <footer className={`border-t transition-colors duration-300 mt-16
        ${isDarkMode ? 'border-gray-800' : 'border-white/20'}`}>
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex space-x-6">
              {[
                { icon: <Github className="w-6 h-6" />, href: "https://github.com/MBassi91" },
                { icon: <Linkedin className="w-6 h-6" />, href: "https://linkedin.com/in/matteo-bassi" },
              ].map((social, index) => (
                <MagneticButton
                  key={index}
                  as="a"
                  href={social.href}
                  className="text-white/60 hover:text-white transition-colors"
                >
                  {social.icon}
                </MagneticButton>
              ))}
            </div>
            <p className="text-white/60">
              © {new Date().getFullYear()} Matteo Bassi. Built with React & 💙
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;